import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function ContactForm() {
  return (
    <Section className="has-background-grey-lighter is-medium">
      <Container className="content">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          action="/contact/thanks"
        >
          <input type="hidden" name="form-name" value="contact" />
          <Columns>
            <Columns.Column className="is-12">
              <h2 className="title mb-2">Your app enquiry</h2>
              <p>
                Fill out this form and we'll be in touch within 30 minutes,
                usually.
              </p>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column className="is-5">
              <p className="is-hidden">
                <label>
                  Don’t fill this out if you’re human:{' '}
                  <input name="bot-field" />
                </label>
              </p>
              <div className="field mt-6">
                <label className="label">Your name*</label>
                <div className="control">
                  <input
                    className="input is-success"
                    type="text"
                    name="name"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Your email*</label>
                <div className="control">
                  <input
                    className="input is-success"
                    type="email"
                    name="email"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Your telephone</label>
                <div className="control">
                  <input
                    className="input is-success"
                    type="text"
                    name="telephone"
                  />
                </div>
              </div>
            </Columns.Column>
            <Columns.Column className="is-offset-1 is-6 is-centered">
              <div className="field mt-6">
                <label className="label">How can we help you?</label>
                <div className="control">
                  <textarea
                    className="textarea is-success"
                    name="message"
                    required
                  />
                </div>
              </div>

              <div className="field mt-0">
                <label className="label">
                  How much do you expect to invest?
                </label>

                <div className="control">
                  <label className="radio pr-1">
                    <input
                      type="radio"
                      name="budget"
                      className="pr-1 mr-1"
                      value="Not Sure"
                    />
                    Not sure
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="budget"
                      className="pr-1 mr-1"
                      value="£100k+"
                    />
                    &pound;100K+
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="budget"
                      className="pr-1 mr-1"
                      value="£200k+"
                    />
                    &pound;200K+
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="budget"
                      className="pr-1 mr-1"
                      value="£500k+"
                    />
                    &pound;500K+
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="budget"
                      className="pr-1 mr-1"
                      value="£1M+"
                    />
                    &pound;1M+
                  </label>
                </div>
              </div>

              <p className="has-text-right mt-6">
                <button
                  className="button is-primary is-leafy is-medium"
                  type="submit"
                >
                  OK, send this enquiry
                </button>
              </p>
            </Columns.Column>
          </Columns>
        </form>
        <Separator className="is-hidden-mobile mt-6" rtl />
      </Container>
    </Section>
  )
}

export default ContactForm
