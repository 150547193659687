/* eslint-disable no-irregular-whitespace */
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { Container, Navbar } from 'react-bulma-components'

function SiteNav(props) {
  const burgerRef = useRef(null)

  // Nav Menu Toggle Handler
  const navMenuOpenHandler = () => {
    burgerRef.current.classList.toggle('is-active')
    const navBurger = document.getElementById('nav-burger')
    navBurger.classList.toggle('is-active')
  }

  return (
    <div>
      <div>
        {props.noTopBar ? (
          ''
        ) : (
          <>
            <div className="has-background-dark has-text-white has-text-centered is-size-6 pt-2 pb-2">
              <i className="fa fa-rocket is-size-6 has-text-primary ml-2" />{' '}
              Build a thriving app with this{' '}
              <Link
                to="/resources/badly-drawn-mobile"
                className="has-text-primary"
              >
                {' '}
                FREE guide{' '}
              </Link>
            </div>
            <div className="is-hidden has-background-light has-text-light has-text-centered is-size-6 pt-2 pb-2">
              💙 💛{' '}
              <a
                href="https://thetoucan.app/toucan/ukraine-crisis?utm_source=pocketworks"
                target="_blank"
                rel="noreferrer"
              >
                Donate to support the people affected by the crisis in Ukraine
              </a>
            </div>
          </>
        )}
      </div>

      <Container
        className={props.whiteRoundedNav ? 'white-rounded-nav mt-5' : ''}
      >
        <Navbar className="mt-3 is-light">
          <Navbar.Brand>
            <Navbar.Item renderAs="span">
              <Link to="/">
                {props.className === 'is-light' ? (
                  <img
                    className="pt-1 pb-1"
                    src={
                      props.fullLogo
                        ? 'https://www.datocms-assets.com/20955/1579120308-secondary-logo-white-v3.png'
                        : 'https://www.datocms-assets.com/20955/1579431602-secondary-logo-small-square-white-v3a.png'
                    }
                    alt="Pocketworks Logo"
                  />
                ) : (
                  <img
                    className="pt-1 pb-1"
                    src={
                      props.fullLogo
                        ? 'https://www.datocms-assets.com/20955/1579120310-secondary-logo-small-v3.png'
                        : 'https://www.datocms-assets.com/20955/1579431570-secondary-logo-small-square-v3a.png'
                    }
                    alt="Pocketworks Logo"
                  />
                )}
              </Link>
            </Navbar.Item>
            <Navbar.Burger
              id="nav-burger"
              className="navbar-burner no-print"
              data-target="site-menu"
              onClick={navMenuOpenHandler}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </Navbar.Burger>
          </Navbar.Brand>

          <div
            id="site-menu"
            className="navbar-menu navbar-end no-print"
            ref={burgerRef}
          >
            <Navbar.Item className={props.className} renderAs="span">
              <Link
                to="/about/careers"
                activeClassName="is-active"
                partiallyActive
                className="tag is-success is-hidden"
              >
                <i className="fa-brands fa-apple" /> &nbsp;We're hiring!
              </Link>
            </Navbar.Item>
            {/* <Navbar.Item className={props.className} renderAs="span"><Link to="https://leedsdigitalfestival.org/events/eye-spy-designing-customer-experiences-in-a-world-full-of-distractions/" activeClassName="is-active" partiallyActive={true} className="tag is-warning is-size-6">
            <StaticImage src="https://leedsdigitalfestival.org/wp-content/uploads/2022/08/ldf.png" height={18}></StaticImage> &nbsp;See you on 28th Sept? </Link>
            </Navbar.Item> */}

            <Navbar.Item className={props.className} renderAs="span">
              <Link to="/" activeClassName="is-active" partiallyActive={false}>
                Home
              </Link>
            </Navbar.Item>
            <Navbar.Item className={props.className} renderAs="span">
              <Link to="/services" activeClassName="is-active" partiallyActive>
                Services
              </Link>
            </Navbar.Item>
            <Navbar.Item className={props.className} renderAs="span">
              <Link
                to="/mobile-strategy-toolkit"
                activeClassName="is-active"
                partiallyActive
              >
                Resources
              </Link>
            </Navbar.Item>
            <Navbar.Item className={props.className} renderAs="span">
              <Link
                to="/about/story"
                activeClassName="is-active"
                partiallyActive
              >
                About
              </Link>
            </Navbar.Item>
            <Navbar.Item className={props.className} renderAs="span">
              <Link to="/work" activeClassName="is-active" partiallyActive>
                Work
              </Link>
            </Navbar.Item>
            <Navbar.Item className={props.className} renderAs="span">
              <Link to="/blog" activeClassName="is-active" partiallyActive>
                Blog
              </Link>
            </Navbar.Item>
            <Navbar.Item className={props.className} renderAs="span">
              <Link
                to="/contact"
                activeClassName="is-active"
                partiallyActive
                className="button is-leafy is-medium is-primary"
              >
                Let's talk
              </Link>
            </Navbar.Item>
          </div>
        </Navbar>
      </Container>
    </div>
  )
}

SiteNav.propTypes = {
  fullLogo: PropTypes.bool,
  className: PropTypes.string,
}

export default SiteNav
